import React from "react";
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';
import ContactUs from '../components/ContactForm';
import FooterBackgroundImage from '../images/connection_background.png';
import FooterBottom from '../components/FooterBottom';

const Footer = ()=>{
    const {t}= useTranslation();
    return(
        <footer id="lets_talk">
      <section className="FooterSection">
      <div className="SectionWidth">
        <div className="FormBackground">
        <div className="FooterTitleDesc">
        <h2 className={i18n.language==='kr'?'Title MainFont-kr':"Title MainFont"}>{t("Let's talk")}</h2>
        <p className={i18n.language ==='kr'? 'SubFont-kr SectionDescription':"SubFont SectionDescription"}>{t("footer.Contact us Desc")}</p>
        </div>
        <ContactUs/>
        </div>
      </div>
      <img src={FooterBackgroundImage} className="FooterBackground" alt=""/>
      </section>
      <FooterBottom/>
      </footer>
    )
}

export default Footer;