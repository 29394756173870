import React,{useRef} from 'react';
import {useTranslation} from 'react-i18next'
import i18n from '../i18n';
import {motion, useInView} from 'framer-motion';


const ThirdTitle =()=>{
    const {t} = useTranslation();
    const ref= useRef(null);
    const inView = useInView(ref);


    return(<>
    <motion.h3 
    ref={ref}
    initial = "hidden"
        animate={inView ? "visible": "hidden"}
        transition={{duration:0.4, delay: 0.10}}
        variants={{
            visible :{opacity: 1},
            hidden:{opacity : 0}
        }} 
    className={i18n.language==='kr'?'MainFont-kr ThirdTitleGroup':"MainFont ThirdTitleGroup"}>
        <span className={i18n.language==='kr'?'ThirdSmallTitle':"ThirdSmallTitle"}>
        {t("thirdSection.We live in exciting times")}
        </span><br/>
        <span className={i18n.language==='kr'?'Title':'Title'}>
        {t("thirdSection.the whole world is meeting daily in a few platforms and spaces.")}</span></motion.h3></>)
}

export default ThirdTitle