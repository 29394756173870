import React,{useRef} from 'react';
import { motion, useInView } from 'framer-motion';
import {useTranslation} from 'react-i18next'
// import '../Hero.css'
import i18n from '../i18n';

const Hero = ()=>{
    const {t} = useTranslation();
    const ref = useRef(null);
    const inView = useInView(ref);


    return(
        <div
        className="HeroTitleGroup">
            <div className="HeroTitleCol">
            <div className="HeroTitleRow">
            <motion.h2 
            ref={ref} 
            initial="hidden"
            animate={inView ? "visible": "hidden"}
            transition={{ duration: 0.4, delay: 0.10}}
            variants={{
                visible: { opacity: 1},
                hidden: { opacity: 0 }
            }}
            className={i18n.language === 'kr' ?  'MainFont-kr  HeroText-kr SectionDescription': 'MainFont HeroText'}>{t("hero.Everything is")} 
            <span className={`MainFont-${i18n.language} HerohighLight1`}>{t("hero.Relative")}</span></motion.h2>
            </div>
            <div className="HeroTitleRow">
            </div>
            <motion.h2 
            ref={ref} 
            initial="hidden"
            animate={inView ? "visible": "hidden"}
            transition={{ duration: 0.4, delay: 0.60}}
            variants={{
                visible: { opacity: 1},
                hidden: { opacity: 0 }
            }}
            className={i18n.language === 'kr' ?  'MainFont-kr HeroText-kr SectionDescription': 'MainFont HeroText'}>
            {t("hero.Except for what is")} <span className="HerohighLight2">{t("hero.Absolute")} </span>
            </motion.h2>
            </div>
            <motion.p 
            ref={ref} 
            initial="hidden"
            animate={inView ? "visible": "hidden"}
            transition={{ duration: 0.4, delay: 1.0}}
            variants={{
                visible: { opacity: 1},
                hidden: { opacity: 0 }
            }}
            className={i18n.language === 'kr' ?  'SubFont-kr HeroDescription': 'MainFont HeroDescription'}>{t("hero.Our mission is to connect humans on a deep level.")}</motion.p>
        </div>
    )
}

export default Hero;