import  React from "react";

import PrivacyPolicy from "../components/PrivacyPolicy"

// import "../Toggle.css";
// import "../index.css";

const PrivacyPolicyPage=()=>{

    return(
    <div id="privacy_policy" className="FirstSection SectionWidth">
        <PrivacyPolicy/>
    </div>
    )
    
}

export default PrivacyPolicyPage;
