import React from 'react';
import {Link} from 'react-router-dom';


const FooterBottom =()=>{
    return(
        <div className="FooterBottom">
      <div className="SectionWidth">
        <div className="FooterBand">
          <p className="SubFont">© 2023 Deep Bridge. All rights reserved.</p>
           <Link to="/privacy_policy" className="SubFont" target='_blank'>Privacy Policy
          </Link>
        </div>
      </div>
      </div>
    )
}

export default FooterBottom