import  React,{useRef} from "react";
import {useTranslation} from 'react-i18next'
import {motion, useInView} from 'framer-motion';
import i18n from "../i18n";


const FourthTitle =()=>{
    const {t} = useTranslation();
    const ref = useRef(null);
    const inView = useInView(ref);
    return(<>
    <motion.h3 
    ref={ref}
    initial = "hidden"
    animate={inView ? "visible": "hidden"}
    transition={{duration:0.4, delay: 0.10}}
    variants={{
        visible :{opacity: 1},
        hidden:{opacity : 0}
    }} 
    className={i18n.language==='kr'? 'Title MainFont-kr': "Title MainFont"}>{t("fourthSection.There was no such thing")}</motion.h3>
    <motion.h3 
    ref={ref}
    initial = "hidden"
    animate={inView ? "visible": "hidden"}
    transition={{duration:0.4, delay: 0.60}}
    variants={{
        visible :{opacity: 1},
        hidden:{opacity : 0}
    }} 
    className={i18n.language==='kr'? 'Title MainFont-kr': "Title MainFont"}>{t("fourthSection.as a Deep Bridge")}</motion.h3></>)
}

export default FourthTitle