'use client'

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import Send from '../images/send.svg'
import { PhoneInput, usePhoneValidation } from 'react-international-phone';
import i18n from "../i18n";
import {Link} from 'react-router-dom';


const ContactUs = () => {
  const form = useRef();
  const { t } = useTranslation();
  const [phone, setPhone] = useState('');
  const phoneValidation = usePhoneValidation(phone);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ouba78o",
        "template_moxgym8",
        form.current,
        "3f-Zo-EJmOUVwvXcw"
      )
      .then(
        (result) => {
          window.location.reload(true);
        },
        (error) => {
          alert("Failed to send the message! Please try again later");
        }
      );
  };

  return (
    <div className="ContactFormBackground">
      <form className="ContactForm" ref={form} onSubmit={sendEmail}>
        <div className="FormRow">
        <label className="FirstRowDetail inputBorder">
          <input className="SubFont"
            type="text"
            name="user_fullname"
            placeholder={t("footer.Full Name")} required
          />
        </label>

        <div className="Phone inputBorder">
        <PhoneInput
        defaultCountry="it"
        inputProps={{
          name:"phone_number"
        }}
        value={phone}
        onChange={(phone) => setPhone(phone)}
        />
        </div>
        </div>
        <div className="FormRow">
        <label className="FirstRowDetail inputBorder">
          <input className="SubFont"
            type="email"
            name="user_email"
            placeholder={t("footer.Email")} required
          />
        </label>
        <label className="FirstRowDetail inputBorder">
            <select className="SubjectSelector SubFont" name="subject" required>
                <option className={i18n.language==='kr'? 'SubFont-kr': 'SubFont'}data-subject="" defaultChecked disabled>{t("footer.Subject.Subject")}</option>
                <option className={i18n.language==='kr'? 'SubFont-kr': 'SubFont'}data-subject="Analytics and Reporting">{t("footer.Subject.Analytics and Reporting")}</option>
                <option className={i18n.language==='kr'? 'SubFont-kr': 'SubFont'}data-subject="E-commerce Solutions">{t("footer.Subject.E-commerce Solutions")}</option>
                <option className={i18n.language==='kr'? 'SubFont-kr': 'SubFont'}data-subject="Email Marketing">{t("footer.Subject.Email Marketing")}</option>
                <option className={i18n.language==='kr'? 'SubFont-kr': 'SubFont'}data-subject="Pay-Per-Click Advertising (PPC)">{t("footer.Subject.Pay-Per-Click Advertising (PPC)")}</option>
                <option data-subject="Content Marketing">{t("footer.Subject.Content Marketing")}</option>
                <option className={i18n.language==='kr'? 'SubFont-kr': 'SubFont'}data-subject="Search Engine Optimization (SEO)">{t("footer.Subject.Search Engine Optimization (SEO)")}</option>
                <option className={i18n.language==='kr'? 'SubFont-kr': 'SubFont'}data-subject="Website Design and Development">{t("footer.Subject.Website Design and Development")}</option>
                <option className={i18n.language==='kr'? 'SubFont-kr': 'SubFont'}data-subject="Marketing & Communication Strategy">{t("footer.Subject.Marketing & Communication Strategy")}</option>
                <option data-subject="Etc.">Etc.</option>
            </select>
        </label>
        </div>
        <label className="inputBorder">
          <textarea className={i18n.language==='kr'?'SubFont-kr':"SubFont"} name="message" placeholder={t("footer.Details")} />
          </label>
          <label className="CheckboxAgree">
            <input type="checkbox" name="Privacy Policy Agreement" readOnly defaultChecked required />
            <p className={i18n.language==='kr'?'SubFont-kr Agree':'SubFont Agree'}>{t("footer.Policy")} 
            <Link to="/privacy_policy" target="_blank"> Privacy Policy.*</Link>
            </p>
          </label>
          <button className={i18n.language==='kr'?'SubFont-kr SubmitButton':'SubFont SubmitButton'} type="submit">{t("footer.Submit")}<img src={Send} disabled={!phoneValidation.isValid} alt="" /></button>
      </form>
    </div>
  );
};

export default ContactUs;
