import  React from "react";
import FirstSectionTitleDesc from "../components/FirstSection_TitleDesc";
import FirstSectionLists from "../components/FirstSection_Lists";


const FirstSection=()=>{

    return(
    <div id="what_we_can_help" className="FirstSection SectionWidth">
        <FirstSectionTitleDesc/>
        <FirstSectionLists/>
    </div>
    )
    
}

export default FirstSection;
