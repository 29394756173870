import  React,{useRef} from "react";
import ThirdTitle from "./ThirdTitle";
import ThirdSubtitle from "./ThirdSubtitle";
import i18n from "../i18n";
import {useTranslation} from 'react-i18next';
import {motion, useInView} from 'framer-motion';

const ThirdSectionTitleDesc=()=>{
    const {t} = useTranslation();
    const ref = useRef(null);
    const inView = useInView(ref);
    return(
        <div className="TitleDescGroupThird">
        <div className="TitleSubtitleGroup">
        <ThirdSubtitle/>
        <ThirdTitle/>
        </div>
        <motion.div 
        className="DescGroupThird"
        ref={ref}
        initial = "hidden"
        animate={inView ? "visible": "hidden"}
        transition={{duration:0.4, delay: 0.60}}
        variants={{
            visible :{opacity: 1},
            hidden:{opacity : 0}
        }} >
        <p className={i18n.language==='kr'?'SubFont-kr SectionDescription ThirdTitleGroup':'SubFont SectionDescription ThirdTitleGroup'}>{t("thirdSection.description1")}</p>
        <p className={i18n.language==='kr'?'SubFont-kr SectionDescription ThirdTitleGroup':'SubFont SectionDescription ThirdTitleGroup'}>{t("thirdSection.description2")}</p>
        <p className={i18n.language==='kr'?'SubFont-kr SectionDescription ThirdTitleGroup':'SubFont SectionDescription ThirdTitleGroup'}>{t("thirdSection.description3")}</p>
        </motion.div>
        </div>
        )
}

export default ThirdSectionTitleDesc