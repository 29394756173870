import  React,{useRef} from "react";
import FourthTitle from "./FourthTitle";
import FourthSubtitle from "./FourthSubtitle";
// import "../index.css";
import {useTranslation} from 'react-i18next';
import {motion, useInView} from 'framer-motion';


const FourthSectionTitleDesc=()=>{
    const {t} = useTranslation();
    const ref = useRef(null);
    const inView = useInView(ref);
    return(
        <div className="FlexEndAlignment">
        <div className="TitleDescGroupFourth">
        <div className="TitleSubtitleGroup">
        <FourthSubtitle/>
        <FourthTitle/>
        </div>
        <motion.p 
        ref={ref}
        initial = "hidden"
        animate={inView ? "visible": "hidden"}
        transition={{duration:0.4, delay: 0.60}}
        variants={{
            visible :{opacity: 1},
            hidden:{opacity : 0}
        }} 
        className="SubFont SectionDescription TitleDescGroupFourth">{t("fourthSection.description1")}</motion.p>
        </div>
        </div>
        )
}

export default FourthSectionTitleDesc