import  * as React from "react";
import Hero from "../components/Hero";
import Spline from '@splinetool/react-spline';

const HeroRow=()=>{
    return(
    <div id="HeroSection" className="RowSection SectionWidth HeroRowSection">
    <Hero />
    <Spline scene="https://prod.spline.design/TimDkEBrjFXIB-eP/scene.splinecode" />
    {/* <iframe src='https://my.spline.design/untitled-77855bca7c2a697c3c45c9ee0af1289d/' frameborder='0' width='100%' height='100%'></iframe> */}
    </div>
    )
    
    
}



export default HeroRow;
