import React from 'react';
import Logo from '../images/deepbridge_logo.svg';


const DeepBridgeLogo =()=>{

    return(<img src={Logo} alt="Deep Bridge Digital" />)
        

}

export default DeepBridgeLogo