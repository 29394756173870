import  * as React from "react";
import ThirdSectionTitleDesc from "../components/ThirdSection_TitleDesc";
// import "../Toggle.css";
// import "../index.css";

const ThirdSection=()=>{
    return(
    <div id="who_we_can_help"
    className="ThirdSection SectionWidth">
        <ThirdSectionTitleDesc/>
    </div>
    )
    
}

export default ThirdSection;
