import  * as React from "react";
import SecondSubtitle from "./SecondSubtitle";
// import "../index.css";

const FirstSectionTitleDesc=()=>{
    return(
        <div className="TitleSubtitleGroup">
        <SecondSubtitle/>
        </div>
        )
}

export default FirstSectionTitleDesc