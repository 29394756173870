import React from 'react';
// import "../index.css";
import HeroRow from '../templates/HeroRow';
// import '../Globe.css'
import FirstSection from '../templates/FirstSection';
import SecondSection from '../templates/SecondSection';
import ThirdSection from '../templates/ThirdSection';
import FourthSection from '../templates/FourthSection';

// import '../Footer.css';


const Home=()=> {
  return (
    <>
      <HeroRow />
      <FirstSection/>
      <SecondSection/>
      <ThirdSection/>
      <FourthSection />
    </>
    
  );
  
}

export default Home;
