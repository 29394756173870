import  React,{useRef}  from "react";
import FirstTitle from "./FirstTitle";
import FirstSubtitle from "./FirstSubtitle";
import i18n from "../i18n";
import {useTranslation} from 'react-i18next'
import { motion, useInView } from 'framer-motion';


const FirstSectionTitleDesc=()=>{
    const {t} = useTranslation();
    const ref = useRef(null);
    const inView = useInView(ref);

    return(
        <div className="TitleDescGroup">
        <div className="TitleSubtitleGroup">
        <FirstSubtitle/>
        <FirstTitle/>
        </div>
        <motion.p ref={ref}
        initial = "hidden"
        animate={inView ? "visible": "hidden"}
        transition={{duration:0.4, delay: 1.0}}
        variants={{
        visible :{opacity: 1},
        hidden:{opacity : 0}
        }} 
    className={i18n.language === 'kr' ? 'SectionDescription SubFont-kr' : 'SectionDescription SubFont'}>{t("firstSection.description")}</motion.p>
        </div>
        )
}

export default FirstSectionTitleDesc