import React from 'react';
import DeepBridgeLogo from './Logo';
import LanguageSelector from './LanguageSelector';
import {useTranslation} from 'react-i18next'


const NavBarHeader=()=>{

  const {t} = useTranslation();
    return(
      <header>
      <nav className="HeaderNav">
        <a href="/" className="DeepBridgeLogoStyle">
              <DeepBridgeLogo/>
        </a>
        
        <ul>
          
            <li><a href="#what_we_can_help" className="NavLink SubFont">{t("What we can help")}</a></li>
          
          
            <li><a href="#weve_worked_with" className="NavLink SubFont">{t("We've worked with")}</a></li>
          
          
            <li><a href="#who_we_can_help" className="NavLink SubFont">{t("Who we can help")}</a></li>
          
          
            <li><a href="#who_we_are" className="NavLink SubFont">{t("Who we are")}</a></li>
          
          
            <li><a href="#lets_talk" className="NavLink SubFont">{t("Let's talk")}</a></li>
          
          </ul>
          <LanguageSelector />
      </nav>
      </header>
    )
    
  };


export default NavBarHeader