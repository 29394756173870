import React from 'react';
// import '../SideBar.css';
import SideBarButton from '../images/sidebarbutton.svg'


const SideBar = ()=>{
    return(
        <div className="SideBar">
        <a href="#lets_talk" className="AnchorSide">
        <img src={SideBarButton} alt="DeepBridgeDigital" />
        </a>
      </div>
    )
}

export default SideBar;
