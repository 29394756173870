import  * as React from "react";
import FourthSectionTitleDesc from "../components/FourthSection_TitleDesc";
import DeepBridgeImage from "../components/FourthImageFramer";
// import "../index.css";

const FourthSection=()=>{
    return(

        <div id="who_we_are" className="FourthSection">
        <DeepBridgeImage/>
          <FourthSectionTitleDesc/>
        </div>
    )
    
}

export default FourthSection;
