
import NavBarHeader from "./components/NavBarHeader";
import SideBar from './templates/SideBar';
import FloatingNav from './templates/FloatingNav';
import {Route, Routes} from 'react-router-dom';
import PrivacyPolicyPage from "./templates/PrivacyPolicyPage"
import Home from './templates/Home';
import Footer from './templates/Footer';
import './App.css';
// import Loading from './components/Loading';

function App() {
  // const [loading, setLoading]=useState(true);
  // useEffect(() =>{
  //   setTimeout(()=>setLoading(false), 4000)
  // },[])
  // if (loading){
  //   return<Loading/>
  // }
  return (
    <>
    <div className="App">
      <SideBar/>
      <NavBarHeader />
      <FloatingNav />
      <Routes>
        <Route path="/privacy_policy" element={<PrivacyPolicyPage />}></Route> 
        <Route path="/" element={<Home />}></Route>
      </Routes>
      <Footer/>
    </div>
    </>
  );
}

export default App;
