import  React from "react";
// import "../index.css";


const PrivacyPolicy=()=>{

    return(
        // <div className="TitleDescGroup">
        // <div className="TitleSubtitleGroup">
<div>
<div>
<div className="TopBottomMargin">
<h2 className="Title MainFont">Privacy Policy for Deep Bridge</h2>
        
<p className="SubFont SectionDescription">At Deep Bridge, accessible from https://deepbridge.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Deep Bridge and how we use it.</p>
<br/>
<p className="SubFont SectionDescription">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
<br/>
<p className="SubFont SectionDescription">This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Deep Bridge. This policy is not applicable to any information collected offline or via channels other than this website.</p>
<br/>
</div>
<div className="TopBottomMargin">
<h2 className="Title MainFont">Consent</h2>

<p className="SubFont SectionDescription">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
</div>

<div className="TopBottomMargin">
<h2 className="Title MainFont">Information we collect</h2>

<p className="SubFont SectionDescription">The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
<br/>
<p className="SubFont SectionDescription">If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
<br/>
<p className="SubFont SectionDescription">When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
</div>
<div className="TopBottomMargin">
<h2 className="Title MainFont">How we use your information</h2>

<p className="SubFont SectionDescription">We use the information we collect in various ways, including to:</p>

<ul className="ColUl">
<li className="SubFont SectionDescription">Provide, operate, and maintain our website</li>
<li className="SubFont SectionDescription">Improve, personalize, and expand our website</li>
<li className="SubFont SectionDescription">Understand and analyze how you use our website</li>
<li className="SubFont SectionDescription">Develop new products, services, features, and functionality</li>
<li className="SubFont SectionDescription">Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
<li className="SubFont SectionDescription">Send you emails</li>
<li className="SubFont SectionDescription">Find and prevent fraud</li>
</ul>
</div>

<div className="TopBottomMargin">
<h2 className="Title MainFont">Log Files</h2>

<p className="SubFont SectionDescription">Deep Bridge follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
</div>


<div className="TopBottomMargin">
<h2 className="Title MainFont">Advertising Partners Privacy Policies</h2>

<p className="SubFont SectionDescription">You may consult this list to find the Privacy Policy for each of the advertising partners of Deep Bridge.</p>
<br/>
<p className="SubFont SectionDescription">Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Deep Bridge, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
<br/>
<p className="SubFont SectionDescription">Note that Deep Bridge has no access to or control over these cookies that are used by third-party advertisers.</p>
</div>

<div className="TopBottomMargin">
<h2 className="Title MainFont">Third Party Privacy Policies</h2>

<p className="SubFont SectionDescription">Deep Bridge's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

<p className="SubFont SectionDescription">You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>
</div>

<div className="TopBottomMargin">
<h2 className="Title MainFont">CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

<p className="SubFont SectionDescription">Under the CCPA, among other rights, California consumers have the right to:</p>
<br/>
<p className="SubFont SectionDescription">Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p><br/>
<p className="SubFont SectionDescription">Request that a business delete any personal data about the consumer that a business has collected.</p><br/>
<p className="SubFont SectionDescription">Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p><br/>
<p className="SubFont SectionDescription">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
</div>


<div className="TopBottomMargin">
<h2 className="Title MainFont">GDPR Data Protection Rights</h2>

<p className="SubFont SectionDescription">We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p><br/>
<p className="SubFont SectionDescription">The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p><br/>
<p className="SubFont SectionDescription">The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p><br/>
<p className="SubFont SectionDescription">The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p><br/>
<p className="SubFont SectionDescription">The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p><br/>
<p className="SubFont SectionDescription">The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p><br/>
<p className="SubFont SectionDescription">The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p><br/>
<p className="SubFont SectionDescription">If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
</div>

<div className="TopBottomMargin">
<h2 className="Title MainFont">Children's Information</h2>

<p className="SubFont SectionDescription">Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
<br/>
<p className="SubFont SectionDescription">Deep Bridge does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
</div>


<div className="TopBottomMargin">
<h2 className="Title MainFont">Changes to This Privacy Policy</h2>

<p className="SubFont SectionDescription">We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p><br/>

<p className="SubFont SectionDescription">Our Privacy Policy was created with the help of the <a href="https://www.termsfeed.com/privacy-policy-generator/">Privacy Policy Generator</a>.</p>
</div>

<div className="TopBottomMargin">
<h2 className="Title MainFont">Contact Us</h2>

<p className="SubFont SectionDescription">If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
</div>
        </div>
        </div>
        )
}

export default PrivacyPolicy