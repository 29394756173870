import React from "react";
import { motion} from "framer-motion";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import i18n from "../i18n";
import { flushSync } from "react-dom";
// import "../Toggle.css";
// import "../index.css";

const FirstSectionLists = () => {
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [isActive5, setIsActive5] = useState(false);
  const [isActive6, setIsActive6] = useState(false);
  const [isActive7, setIsActive7] = useState(false);
  const [isActive8, setIsActive8] = useState(false);
  const [isActive9, setIsActive9] = useState(false);
  const [isActive10, setIsActive10]= useState(false);
  const { t } = useTranslation();

  const ListDecorator ={
      ListDeco:{display:'flex', 
      borderLeft:'1px solid #01ffa4', 
      padding:'0.5rem 0.75rem', 
      transition:{
        type: 'fade'
      }},
      ListDefault:{display: 'flex',
        padding: '0.5rem 0.75rem',
        borderLeft: '1px solid transparent',
        transition:{
          type: 'fade'
        }}

  }

  return (
    <div className="ListWidth">
      <ul className="ListCol">
      
        <li className="ListCol" onClick={() => setIsActive(!isActive)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} 
          >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            
            className={i18n.language ==='kr' ? 'MainFont-kr ListTitle' : 'MainFont ListTitle'}>
              {t("firstSection.Analytics and Reporting.title")}
            </motion.span>
          </motion.div>
          {isActive && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language ==='kr' ? 'SubFont-kr ListDescription' : 'SubFont ListDescription'}>
                  {t("firstSection.Analytics and Reporting.description")}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive2(!isActive2)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive2 ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            className={i18n.language==='kr' ? 'MainFont-kr ListTitle' : 'MainFont ListTitle'}>
              {t("firstSection.E-commerce Solutions.title")}
            </motion.span>
          </motion.div>
          {isActive2 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language === 'kr' ? 'SubFont-kr ListDescription' : 'SubFont ListDescription'}>
                  {t("firstSection.E-commerce Solutions.description")}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive9(!isActive9)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive9 ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} 
          >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            
            className={i18n.language ==='kr' ? 'MainFont-kr ListTitle' : 'MainFont ListTitle'}>
              {t("firstSection.GUI (Graphic User Interface) Design.title")}
            </motion.span>
          </motion.div>
          {isActive9 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language ==='kr' ? 'SubFont-kr ListDescription' : 'SubFont ListDescription'}>
                  {t("firstSection.GUI (Graphic User Interface) Design.description")}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive10(!isActive10)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive10 ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} 
          >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            
            className={i18n.language ==='kr' ? 'MainFont-kr ListTitle' : 'MainFont ListTitle'}>
              {t("firstSection.App Design.Title")}
            </motion.span>
          </motion.div>
          {isActive10 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language ==='kr' ? 'SubFont-kr ListDescription' : 'SubFont ListDescription'}>
                  {t("firstSection.App Design.description")}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive3(!isActive3)}>
          <motion.div 
         whileHover={ListDecorator.ListDeco}
         animate={isActive3 ?'ListDeco':'ListDefault'} 
         variants={ListDecorator} >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            
            className={i18n.language==='kr'?'MainFont-kr ListTitle':'MainFont ListTitle'}>
              {t("firstSection.Email Marketing.title")}
            </motion.span>
          </motion.div>
          {isActive3 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language ==='kr' ? 'SubFont-kr ListDescription': 'SubFont ListDescription'}>
                  {t("firstSection.Email Marketing.description")}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive4(!isActive4)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive4 ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            
            className={i18n.language === 'kr'? 'MainFont-kr ListTitle': "MainFont ListTitle"}>
              {t("firstSection.Pay-Per-Click Advertising (PPC).title")}
            </motion.span>
          </motion.div>
          {isActive4 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language==='kr' ? 'SubFont-kr ListDescription': 'SubFont ListDescription'}>
                  {t(
                    "firstSection.Pay-Per-Click Advertising (PPC).description"
                  )}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive5(!isActive5)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive5 ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} >
          
                <motion.span 
                initial={{color: '#fff'}}
                whileHover={{
                color: '#c2c0ba'
            }}
               
                className={i18n.language ==='kr'?'MainFont-kr ListTitle':'MainFont ListTitle'}>
              {t("firstSection.Content Marketing.title")}
            </motion.span>
          </motion.div>
          {isActive5 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language ==='kr'?'SubFont-kr ListDescription':'SubFont ListDescription'}>
                  {t("firstSection.Content Marketing.description")}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive6(!isActive6)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive6 ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            
            className={i18n.language==='kr'? 'MainFont-kr ListTitle': "MainFont ListTitle"}>
              {t("firstSection.Search Engine Optimization (SEO).title")}
            </motion.span>
          </motion.div>
          {isActive6 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language ==='kr'?'SubFont-kr ListDescription':"SubFont ListDescription"}>
                  {t(
                    "firstSection.Search Engine Optimization (SEO).description"
                  )}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive7(!isActive7)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive7 ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            
            className={i18n.language==='kr'?'MainFont-kr ListTitle':"MainFont ListTitle"}>
              {t("firstSection.Website Design and Development.title")}
            </motion.span>
          </motion.div>
          {isActive7 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language==='kr'?'SubFont-kr ListDescription':"SubFont ListDescription"}>
                  {t("firstSection.Website Design and Development.description")}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
        <li className="ListCol" onClick={() => setIsActive8(!isActive8)}>
          <motion.div 
          whileHover={ListDecorator.ListDeco}
          animate={isActive8 ?'ListDeco':'ListDefault'} 
          variants={ListDecorator} >
          
            <motion.span 
            initial={{color: '#fff'}}
            whileHover={{
                color: '#c2c0ba'
            }}
            className={i18n.language==='kr'? 'MainFont-kr ListTitle':'MainFont ListTitle'}>
              {t("firstSection.Marketing & Communication Strategy.title")}
            </motion.span>
          </motion.div>
          {isActive8 && (
            <motion.ul
              initial={{ y: 0, visible: "hidden" }}
              animate={{ y: 1, duration: 1000, ease: "easeInOut" }}
              className="ListCol"
            >
              <li className="ListDescAlign">
                <span className={i18n.language==='kr'?'SubFont-kr ListDescription':"SubFont ListDescription"}>
                  {t(
                    "firstSection.Marketing & Communication Strategy.description"
                  )}
                </span>
              </li>
            </motion.ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default FirstSectionLists;
