import React from 'react';
import {useTranslation} from 'react-i18next'
// import "../index.css";



const ThirdSubtitle =()=>{
    const {t} = useTranslation();
    return(<>
    <h3 className="SubTitle SubFont ThirdTitleGroup">{t("Who we can help")}</h3></>)
}

export default ThirdSubtitle