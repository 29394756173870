import React,{useRef} from 'react';
import {useTranslation} from 'react-i18next'
import {motion, useInView} from 'framer-motion'
import i18n from '../i18n';


const FirstTitle =()=>{
    const {t} = useTranslation();
    const ref = useRef(null);
    const inView = useInView(ref);
    return(<>
    <motion.h3 
    ref={ref}
    initial = "hidden"
    animate={inView ? "visible": "hidden"}
    transition={{duration:0.4, delay: 0.10}}
    variants={{
        visible :{opacity: 1},
        hidden:{opacity : 0}
    }} 
    className={i18n.language === 'kr' ?  'MainFont-kr Title': 'Title MainFont'}>{t("firstSection.Beyond the digital")}</motion.h3><motion.h3 
    className={i18n.language === 'kr' ?  'MainFont-kr Title': 'Title MainFont'}
    ref={ref}
    initial = "hidden"
    animate={inView ? "visible": "hidden"}
    transition={{duration:0.4, delay: 0.60}}
    variants={{
        visible :{opacity: 1},
        hidden:{opacity : 0}
    }} 
    >{t("firstSection.Human connection")}</motion.h3></>)
}

export default FirstTitle