import React from 'react';

// import "../Toggle.css";
// import "../index.css";

import Slider from 'react-infinite-logo-slider'


import LgIcon from "../images/lg_ico.svg";
import BigSunriseIcon from "../images/bigsunrise_ico.svg";
import KlaytnIcon from "../images/klaytn_ico.svg";
import YoloIcon from "../images/yolo_ico.svg";
import ClIcon from "../images/cl_ico.svg";



  
const LogoSlider =()=>{
    return(
        <Slider duration={40}
        pauseOnHover={true}
        blurBorders={false}
        blurBoderColor={'#fff'}>
            <Slider.Slide className="IconBackground"><img src={LgIcon} role="presentation" alt=""/></Slider.Slide>

<Slider.Slide className="IconBackground"><img src={BigSunriseIcon}  role="presentation" alt=""/></Slider.Slide>

<Slider.Slide className="IconBackground"><img src={KlaytnIcon} role="presentation" alt=""/></Slider.Slide>

<Slider.Slide className="IconBackground"><img src={YoloIcon} role="presentation" alt=""/></Slider.Slide>

<Slider.Slide className="IconBackground"><img src={ClIcon}  role="presentation" alt=""/></Slider.Slide>
        </Slider>
    )
}

export default LogoSlider;