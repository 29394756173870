import React from "react";
import FloatingLogo from '../images/floatinglogo.svg'
import {useTranslation} from 'react-i18next'
import LanguageSelector from "../components/LanguageSelector";
import {motion}  from 'framer-motion';
import { useState } from "react";
import i18n from "../i18n";

const FloatingNav = ()=>{
    const {t} = useTranslation();
    const WidthMenu = {
        width: "94vw",
    }
    const MaxContentWidth={
      width: "max-content"
    }
    const InnerLogo={
      width: "100%"
    }
    const Show={
      display: "flex",
      opacity:"1",
      height: "100%"
    }
    const Hide={
      display: "none",
      opacity: "0"
    }

    const [isActive, setIsActive] = useState(false);
    return(
       <>
        <nav className="FloatingNav">
        <div className="FloatingLogoBtn">
          <img src={FloatingLogo} alt=""/>
        </div>
        <ul>
          <li className="FloatingMenuBtn"><a href="#what_we_can_help" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("What we can help")}</a></li>
          <li className="FloatingMenuBtn"><a href="#weve_worked_with" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("We've worked with")}</a></li>
          <li 
          className="FloatingMenuBtn"><a href="#who_we_can_help" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("Who we can help")}</a></li>
          <li className="FloatingMenuBtn"><a href="#who_we_are" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("Who we are")}</a></li>
          <li className="FloatingMenuBtn SubFont LanguageSelect"><LanguageSelector className={i18n.language==='kr'?'FloatingMenuBtn SubFont-kr':'FloatingMenuBtn SubFont'}/></li>
          <li className="FloatingMenuBtn TalkBtn"><a href="#lets_talk" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("Let's talk")}</a></li>
          
        </ul>
      </nav>



        <motion.nav className="FloatingNavResp"
        animate={isActive ? WidthMenu:MaxContentWidth}>
        <motion.div 
        onClick={() => setIsActive(!isActive)}
        animate={isActive ? InnerLogo:""}
        className="FloatingLogoBtn">
          <img src={FloatingLogo} alt=""/>
        </motion.div>
        <motion.ul
        initial={{display: "none",height:"0"}}
        transition={{
          duration: 0.1
        }}
        animate={isActive ? Show : Hide}
        >
          <li className="FloatingMenuBtn"><a href="#what_we_can_help" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("What we can help")}</a></li>
          <li className="FloatingMenuBtn"><a href="#weve_worked_with" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("We've worked with")}</a></li>
          <li className="FloatingMenuBtn"><a href="#who_we_can_help" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("Who we can help")}</a></li>
          <li className="FloatingMenuBtn"><a href="#who_we_are" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("Who we are")}</a></li>
          <li className="FloatingMenuBtn TalkBtn"><a href="#lets_talk" className={i18n.language==='kr'?'SubFont-kr':"SubFont"}>{t("Let's talk")}</a></li>
          
        </motion.ul>
      </motion.nav>
      </>
    )
}

export default FloatingNav;