import  * as React from "react";
import SecondSectionTitleDesc from "../components/SecondSection_TitleDesc";
import LogoSlider from "../components/SecondSection_LogoSlider";



const SecondSection=()=>{


    return(
        <div className="Relative">
        <div id="weve_worked_with"></div>
    <div
    className="SecondSection SectionWidth">
        <SecondSectionTitleDesc/>
        <LogoSlider/>
    </div>
    </div>
    )
    
}

export default SecondSection;
